.skillContainer {
    padding-bottom: 1rem;

    .iconContainer {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        align-items: center;
        
        img {
            width: 2rem;
            cursor: help;
        }
    }
}

@media only screen and (min-width: 768px) {
    .skillContainer {
        .iconContainer {
            img {
                width: 3rem;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .skillContainer {
        .iconContainer {
            img {
                width: 4rem;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .skillContainer {
        .iconContainer {
            img {
                filter: grayscale(100%);

                &:hover {
                    filter: grayscale(0%);
                }
            }
        }
    }
}
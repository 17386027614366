* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem 1.5rem;
  line-height: 1.2;
  background-color: rgb(0, 0, 0);
}

#root {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  body, html {
    padding: 1rem 3rem;
  }
}

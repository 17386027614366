.aboutPage {
    max-width: initial;
    padding-bottom: 0.5rem;
    text-align: left;

    span {
        padding-right: 5px;
    }

    li {
        font-size: 1rem;
    }
}

.aboutText {
    list-style-type: none;
    font-size: 20px;
    padding-left: 0;

    li {
        margin-bottom: 15px;
    }

    span {
        margin-right: 5px;
    }
}

@media only screen and (min-width: 768px) {
    .aboutPage {
        h1 {
            font-size: 2.5rem;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .aboutPage {
        li {
            font-size: 1.125rem;
        }
    }
}
.App {
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  max-width: 768px;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

@media only screen and (min-width: 768px) {
  .App {
    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
}
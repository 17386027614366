.projectsContainer {
    padding-bottom: 0.5rem;

    ul {
        padding-left: 0;

        h3 {
            font-size: 1.25rem;
        }

        li {
            list-style-type: none;
            
        }

        li:not(:last-child) {
            border-bottom: 1px solid gray;
        }

        a:hover {
            color: black;
            background-color: white;
        }
    }
}

@media only screen and (min-width: 768px) {
    .projectsContainer {
        ul {
            h3 {
                font-size: 1.5rem;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .projectsContainer {
        ul {
            h2 {
                font-size: 1.75rem;
            }

            p {
                font-size: 1.125rem;
            }
        }
    }
}